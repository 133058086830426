<script>
  import { loadStripe } from "@stripe/stripe-js";
  import { onMount } from "svelte";
  import { Elements } from "svelte-stripe";
  import { get, readable } from "svelte/store";
  import packageJson from "../package.json";
  import CardPaymentModal from "./components/modals/CardPaymentModal.svelte";
  import PaymentModal from "./components/modals/PaymentModal.svelte";
  import StreamPaymentModal from "./components/modals/StreamPaymentModal.svelte";
  import PaymentAlreadyCompleted from "./components/PaymentAlreadyCompleted.svelte";
  import SpiralBottomRightIcon from "./components/icons/SpiralBottomRightIcon.svelte";
  import SpiralTopLeftIcon from "./components/icons/SpiralTopLeftIcon.svelte";
  import ToonieIcon from "./components/icons/ToonieIcon.svelte";
  import {
    getRawPaymentStatusData,
    completeOrderAndRedirect,
    PaymentStatus,
    isPaymentApproved,
  } from "./shared/utils/utils.svelte";
  import {
    checkInvalidOptions,
    formatAmountToDisplay,
    getFirstTwoLetters,
    Providers,
    setError,
  } from "./shared/utils/utils.svelte";
  import {
    cardPaymentModalStore,
    forcePollingStop,
    optionsStore,
    paymentDataStore,
    paymentErrorsStore,
  } from "./store";
  import RegistrationModal from "./components/modals/RegistrationModal.svelte";
  import UserIcon from "./components/icons/UserIcon.svelte";
  import ErrorModal from "./components/modals/ErrorModal.svelte";

  const options = get(optionsStore);
  const paymentSessionIdUrlKey = "orderId";
  const toonieRedirectUrl = "https://www.toonieglobal.com/";
  const PUBLIC_STRIPE_KEY = process.env.PUBLIC_STRIPE_KEY;

  const version = readable(packageJson.version);

  let qrCodeValue;
  let paymentDataBySessionId; // used to retrieve the payment data from the session id
  let paymentData; // used for testing the sdk purposes
  let paymentError;

  // validating options object provided in the render method
  let loadDataError = checkInvalidOptions(options);

  let isPwtButtonVisible = false;
  let isPwtModalVisible = false;
  let paymentShortReference;

  let isCardButtonVisible = false;
  let isCardModalVisible = false;
  let stripe;

  let isStreamButtonVisible = false;
  let isStreamModalVisible = false;
  let streamPaymentData;

  let isRegistrationButtonVisible = false;
  let isRegistrationModalVisible = false;

  let shouldShowNoButton = false;

  let paymentAlreadyCompleted = false;

  let isLoading = true;
  let isCardPaymentInitiating = false;
  let isTooniePaymentInitiating = false;
  let isErrorModalVisible = false;

  cardPaymentModalStore.subscribe((isCardModalOpen) => {
    isCardModalVisible = isCardModalOpen;
  });

  paymentDataStore.subscribe((paymentDataFromStore) => {
    paymentData = paymentDataFromStore;
  });

  paymentErrorsStore.subscribe((error) => {
    paymentError = error;
    if (error) isErrorModalVisible = true;
  });

  onMount(async () => {
    stripe = await loadStripe(PUBLIC_STRIPE_KEY);

    const urlParams = new URLSearchParams(location.search);
    const paymentSessionIdFromUrl = urlParams.get(paymentSessionIdUrlKey);

    if (!paymentSessionIdFromUrl) {
      setError("NO_PAYMENT_SESSION_ID");
      isLoading = false;
    } else {
      try {
        paymentDataBySessionId = await options.fetchPaymentDataBySessionId(
          paymentSessionIdFromUrl,
        );

        if (
          !paymentDataBySessionId.paymentSessionId &&
          paymentDataBySessionId.error
        ) {
          setError(paymentDataBySessionId.error);
          options.failurePaymentCallback(
            "There was an issue initiating the payment session",
          );
          loadDataError = true;
          isLoading = false;
          return;
        }

        if (
          paymentDataBySessionId.status === PaymentStatus.SUCCEEDED ||
          paymentDataBySessionId.status === PaymentStatus.APPROVED ||
          paymentDataBySessionId.status === PaymentStatus.COMPLETED
        ) {
          paymentAlreadyCompleted = true;
          isLoading = false;
          return;
        }

        if (paymentDataBySessionId.status === PaymentStatus.INITIATED) {
          // try to approve the payment - on first page load we could have left an "open" session behind if we lost
          // connection to the server
          try {
            const paymentStatusData = await getRawPaymentStatusData(
              options,
              paymentSessionIdFromUrl,
            );
            if (isPaymentApproved(paymentStatusData)) {
              // update payment for BE and show to user success message
              await completeOrderAndRedirect({
                sessionId: paymentSessionIdFromUrl,
                paymentStatus: paymentStatusData,
                paymentData,
                redirectDelay: 0,
                options,
              });

              return;
            }
          } catch (e) {
            // no fuss
            console.error(e);
          }
        }

        isPwtButtonVisible =
          options.renderPayWithToonieButton &&
          paymentDataBySessionId.providers.includes(Providers.PWT);
        isCardButtonVisible =
          options.renderPayWithCardButton &&
          paymentDataBySessionId.providers.includes(Providers.CARD);
        isStreamButtonVisible =
          options.renderStreamWithToonieButton &&
          paymentDataBySessionId.providers.includes(Providers.STREAM);
        isRegistrationButtonVisible = paymentDataBySessionId.providers.includes(
          Providers.REGISTRATION,
        );

        shouldShowNoButton =
          !isPwtButtonVisible &&
          !isCardButtonVisible &&
          !isStreamButtonVisible &&
          !isRegistrationButtonVisible;

        isLoading = false;
      } catch (e) {
        setError("UNEXPECTED_ERROR");
        options.failurePaymentCallback(e);
        loadDataError = true;
        isLoading = false;
        console.warn("There was an issue with loading data for this payment");
      }
    }
  });

  const toggleLoadingOnButton = (provider, isLoading) => {
    if (provider === Providers.PWT) {
      isTooniePaymentInitiating = isLoading;
    }

    if (provider === Providers.CARD) {
      isCardPaymentInitiating = isLoading;
    }
  };

  const handleInitiatePayment = async (provider) => {
    toggleLoadingOnButton(provider, true);

    if (
      paymentData?.paymentSessionId ||
      paymentDataBySessionId?.paymentSessionId
    ) {
      try {
        paymentData = await options.initiatePayment({
          paymentSessionId: paymentDataBySessionId?.paymentSessionId,
          provider,
        });
        toggleLoadingOnButton(provider, false);
      } catch (error) {
        setError(error);
        options.failurePaymentCallback(error);
        loadDataError = true;
        toggleLoadingOnButton(provider, false);
        console.warn("There was an issue initiating the payment session");
      }
    } else {
      setError("NO_PAYMENT_SESSION_ID");
      toggleLoadingOnButton(provider, false);
      loadDataError = true;
      console.warn("Error on loading payment data: missing paymentSessionId");
    }
  };

  const onPaymentButtonClick = async (selectedProvider) => {
    // reset forcePollingStop because it could have been valued from the modal closing
    forcePollingStop.set(undefined);

    try {
      switch (selectedProvider) {
        case Providers.PWT:
          await handleInitiatePayment(selectedProvider);
          paymentShortReference = paymentData.paymentShortReference;
          qrCodeValue = JSON.stringify({
            paymentSessionId: paymentData.offersSessionId,
            otp: paymentData.otp,
            $: "PWT",
          });
          isPwtModalVisible = true;
          break;
        case Providers.CARD:
          await handleInitiatePayment(selectedProvider);
          if (paymentData.clientSecret && paymentData.stripePaymentIntentId) {
            loadDataError = false;
            isCardModalVisible = true;
          } else {
            loadDataError = true;
            setError("NO_CARD_PAYMENT_PARAMS");
          }
          break;
        case Providers.REGISTRATION:
          isRegistrationModalVisible = true;
          break;
        case Providers.STREAM:
          try {
            streamPaymentData = await options.createStreamPaymentIntent(
              paymentDataBySessionId?.amount,
              paymentDataBySessionId?.currency,
            );

            if (streamPaymentData.intentId) {
              loadDataError = false;
            } else {
              loadDataError = true;
              setError("NO_STREAM_PAYMENT_PARAMS");
            }

            qrCodeValue = JSON.stringify({
              paymentSessionId: streamPaymentData.intentStreamId,
              amount: `${streamPaymentData.amount}`,
              currency: streamPaymentData.currency.toLowerCase(),
              destinationWalledId: streamPaymentData.walletId,
              reason: streamPaymentData.reason,
              $: "Stream",
            });
            isStreamModalVisible = true;
          } catch (error) {
            options.failurePaymentCallback(error);
            loadDataError = true;
            setError("STREAM_UNEXPECTED_ERROR");
          }
          break;
      }
    } catch (error) {
      if (options.genericErrorCallback) {
        options.genericErrorCallback(error);
      }
      loadDataError = true;
      setError("UNEXPECTED_ERROR");
    }
  };

  const onCloseModal = () => {
    isPwtModalVisible = false;
    isCardModalVisible = false;
    isStreamModalVisible = false;
    isRegistrationModalVisible = false;
    isErrorModalVisible = false;

    const paymentError = get(paymentErrorsStore);
    options.onModalClose(paymentError);

    forcePollingStop.set(true);
  };
</script>

{#if isLoading}
  <div class="loader-container">
    <div class="loader"></div>
  </div>
{:else if paymentAlreadyCompleted}
  <PaymentAlreadyCompleted />
{:else}
  <div class="main">
    <div class="left-side">
      <SpiralTopLeftIcon />
      <div class="card">
        <div class="icon">
          {#if paymentDataBySessionId?.merchantDisplayName}
            {getFirstTwoLetters(paymentDataBySessionId?.merchantDisplayName)}
          {:else}
            <UserIcon />
          {/if}
        </div>
        <h1 class="title">
          {formatAmountToDisplay(
            paymentDataBySessionId?.amount,
            paymentDataBySessionId?.currency,
          )}
        </h1>
        <h3 class="subtitle">Total cart</h3>
        <div class="row">
          <p class="label">Merchant:</p>
          <p class="value">{paymentDataBySessionId?.merchantDisplayName}</p>
        </div>
        <div class="row">
          <p class="label">Reason:</p>
          <p class="value">{paymentDataBySessionId?.reason}</p>
        </div>
        <div class="row">
          <p class="label">Checkout Total:</p>
          <p class="value">
            {formatAmountToDisplay(
              paymentDataBySessionId?.amount,
              paymentDataBySessionId?.currency,
            )}
          </p>
        </div>
      </div>
    </div>

    <div class="right-side">
      <SpiralBottomRightIcon />
      <ErrorModal {isErrorModalVisible} error={paymentError} {onCloseModal} />

      <div class="right-side-content">
        <div class="pwt-svg-title">
          <ToonieIcon width="230" height="65" viewBox="0 0 230 65" />
        </div>

        <div class="card card-mobile">
          <div class="icon">
            {#if paymentDataBySessionId?.merchantDisplayName}
              {getFirstTwoLetters(paymentDataBySessionId?.merchantDisplayName)}
            {:else}
              <UserIcon />
            {/if}
          </div>
          <h1 class="title">
            {formatAmountToDisplay(
              paymentDataBySessionId?.amount,
              paymentDataBySessionId?.currency,
            )}
          </h1>
          <h3 class="subtitle">Total cart</h3>
          <div class="row">
            <p class="label">Reason:</p>
            <p class="value">{paymentDataBySessionId?.reason}</p>
          </div>
          <div class="row">
            <p class="label">Checkout Total:</p>
            <p class="value">
              {formatAmountToDisplay(
                paymentDataBySessionId?.amount,
                paymentDataBySessionId?.currency,
              )}
            </p>
          </div>
        </div>

        <div class="column-center">
          {#if shouldShowNoButton}
            <h2 class="subtitle">No payment methods available</h2>
          {:else}
            <h2 class="subtitle">Choose your payment method</h2>

            {#if isPwtButtonVisible}
              <button
                on:click={() => onPaymentButtonClick(Providers.PWT)}
                class="primary-btn"
              >
                {#if isTooniePaymentInitiating}
                  <div class="loader--small"></div>
                {:else}
                  <span class="primary-btn__text">Pay with</span>
                  <ToonieIcon
                    height="27"
                    width="92"
                    viewBox="0 0 92 27"
                    isSmall
                  />
                {/if}
              </button>

              <PaymentModal
                {isPwtModalVisible}
                {paymentShortReference}
                {qrCodeValue}
                {loadDataError}
                {paymentData}
                sessionId={paymentDataBySessionId?.paymentSessionId}
                {onCloseModal}
                successUrl={paymentDataBySessionId?.successUrl}
                errorUrl={paymentDataBySessionId?.errorUrl}
              />
            {/if}

            {#if isStreamButtonVisible}
              <button
                on:click={() => onPaymentButtonClick(Providers.STREAM)}
                class="primary-btn"
              >
                <span class="primary-btn__text">Stream with</span>
                <ToonieIcon
                  height="27"
                  width="92"
                  viewBox="0 0 92 27"
                  isSmall
                />
              </button>

              <StreamPaymentModal
                {isStreamModalVisible}
                {qrCodeValue}
                {loadDataError}
                {streamPaymentData}
                {onCloseModal}
                successUrl={paymentDataBySessionId?.successUrl}
                errorUrl={paymentDataBySessionId?.errorUrl}
              />
            {/if}

            {#if isRegistrationButtonVisible}
              <button
                on:click={() => onPaymentButtonClick(Providers.REGISTRATION)}
                class="primary-btn"
              >
                <span class="primary-btn__text">Pay with card</span>
              </button>

              <RegistrationModal
                {isRegistrationModalVisible}
                {loadDataError}
                {onCloseModal}
                sessionId={paymentDataBySessionId?.paymentSessionId}
                sessionCurrency={paymentDataBySessionId?.currency}
              />
            {:else if isCardButtonVisible}
              <button
                on:click={() => onPaymentButtonClick(Providers.CARD)}
                class="primary-btn"
              >
                {#if isCardPaymentInitiating}
                  <div class="loader--small"></div>
                {:else}
                  <span class="primary-btn__text">Pay with Card</span>
                {/if}
              </button>
            {/if}

            {#if stripe && isCardModalVisible}
              <Elements {stripe}>
                <CardPaymentModal
                  {isCardModalVisible}
                  {loadDataError}
                  {stripe}
                  {paymentData}
                  sessionId={paymentDataBySessionId?.paymentSessionId}
                  {onCloseModal}
                  successUrl={paymentDataBySessionId?.successUrl}
                  errorUrl={paymentDataBySessionId?.errorUrl}
                />
              </Elements>
            {/if}
          {/if}
        </div>

        <div class="footer">
          <div class="footer-row">
            <span>Powered by</span>
            <div class="footer-svg">
              <ToonieIcon height="15" width="70" viewBox="0 0 92 27" isSmall />
            </div>
          </div>
          <div class="footer-row">
            <span style="margin-top: 10px">Version: {$version}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  @import "./styles/styles.scss";
</style>
