<script context="module">
  import { get } from "svelte/store";
  import {
    forcePollingStop,
    optionsStore,
    paymentErrorsStore,
    pollingStore,
  } from "../../store.js";

  import {
    getRawPaymentStatusData,
    isPaymentApproved,
    completeOrderAndRedirect,
    PaymentStatus,
  } from "./utils.svelte";

  const POLL_INTERVAL = 5000;
  const MAX_ATTEMPTS = 40;
  const REDIRECT_DELAY = 2500;

  const poll = async ({
    interval,
    maxAttempts,
    paymentData,
    paymentType,
    successUrl,
    errorUrl,
    sessionId,
  }) => {
    const options = get(optionsStore);

    if (successUrl.includes("{PAYMENT_SESSION_ID}")) {
      successUrl = successUrl.replace(/{PAYMENT_SESSION_ID}/gi, sessionId);
      errorUrl = errorUrl.replace(/{PAYMENT_SESSION_ID}/gi, sessionId);
    }

    let attempts = 0;

    const getPaymentStatusData = async () => {
      try {
        return await getRawPaymentStatusData(options, sessionId);
      } catch (error) {
        options.failurePaymentCallback(error);
      }
    };

    const executePoll = async (resolve, reject) => {
      const paymentStatus = await getPaymentStatusData();
      const provider = paymentStatus?.provider;
      attempts++;

      const handleError = () => {
        // Show to user error message
        paymentErrorsStore.set({
          paymentStatus,
          attempts,
        });

        setTimeout(() => (location.href = errorUrl), REDIRECT_DELAY);

        return reject(new Error("Payment was rejected"));
      };

      if (provider) {
        if (isPaymentApproved(paymentStatus)) {
          // update payment for BE and show to user success message
          await completeOrderAndRedirect({
            sessionId,
            paymentStatus,
            paymentData,
            redirectDelay: REDIRECT_DELAY,
            options,
          });

          return resolve(paymentStatus);
        } else if (provider.status === PaymentStatus.REJECTED) {
          // update payment for BE
          if (options.updatePayment) {
            await options.updatePayment(sessionId, provider.status);
          }
          handleError();
        } else if (maxAttempts && attempts === maxAttempts) {
          handleError();
        } else {
          // continue polling
          const isForcedFromOutside = get(forcePollingStop);
          if (!isForcedFromOutside) {
            const timeoutId = setTimeout(
              executePoll,
              interval,
              resolve,
              reject,
            );
            pollingStore.set(timeoutId);
          }
        }
      } else
        return reject(
          new Error(
            "There's been a problem retrieving payment data from order id",
          ),
        );
    };

    const streamPaymentIntentRequest = async () => {
      try {
        const res = await options.fetchStreamPaymentIntent(
          paymentData.intentId,
        );
        if (res.ok) {
          return await res.json();
        } else {
          options.failurePaymentCallback(
            new Error(
              `Failed to retrieve intent with ID: ${res.id ?? "N/A"}, status: ${res.status}, reason: ${res.reason}`,
            ),
          );
        }
      } catch (error) {
        options.failurePaymentCallback(error);
      }
    };

    const executeStreamPoll = async (resolve, reject) => {
      const paymentStatus = await streamPaymentIntentRequest();
      attempts++;

      const handleError = () => {
        // Show to user error message
        paymentErrorsStore.set({
          paymentStatus,
          attempts,
        });

        setTimeout(() => (location.href = errorUrl), REDIRECT_DELAY);

        return reject(new Error("Payment was rejected"));
      };

      if (paymentStatus.status === PaymentStatus.ACCEPTED) {
        // Show to user success message
        if (options.successPaymentCallback) {
          options.successPaymentCallback(paymentStatus, paymentData);
        }
        paymentErrorsStore.set(undefined);

        if (successUrl) {
          location.href = successUrl;
        }

        return resolve(paymentStatus);
      } else if (paymentStatus.status === PaymentStatus.REJECTED) {
        if (options.failurePaymentCallback) {
          options.failurePaymentCallback(paymentStatus, paymentData);
        }

        handleError();
      } else if (maxAttempts && attempts === maxAttempts) {
        handleError();
      } else {
        // continue polling
        const isForcedFromOutside = get(forcePollingStop);
        if (!isForcedFromOutside) {
          const timeoutId = setTimeout(
            executeStreamPoll,
            interval,
            resolve,
            reject,
          );
          pollingStore.set(timeoutId);
        }
      }
    };

    if (paymentType === "PWT") return new Promise(executePoll);

    if (paymentType === "stream") return new Promise(executeStreamPoll);
  };

  export const pollForNewPayment = (
    paymentData,
    paymentType,
    successUrl,
    errorUrl,
    sessionId,
  ) =>
    poll({
      interval: POLL_INTERVAL,
      maxAttempts: MAX_ATTEMPTS,
      paymentData,
      paymentType,
      successUrl,
      errorUrl,
      sessionId,
    });
</script>
